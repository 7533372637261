import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import { TitleDefault, ContentDefault, BoxDefault } from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'

// Language
import { getLanguage } from 'services/language'

// Icons
import imgIconBack from 'img/icon/back-dark-blue.svg'

// CSS
import 'styles/templates/PostTemplate.scss'

export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    post: wordpressPost(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        introText: intro_text
        description_part_one
        description_part_two
        image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 900) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        quote

        backgroundImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

function Post({
  data: {
    post: {
      title,
      path,
      acf: {
        introText,
        description_part_one: descriptionPartOne,
        description_part_two: descriptionPartTwo,
        image,
        quote,
        backgroundImage
      },
      yoast_meta,
    },
  },
}) {
  const language = getLanguage()

  return (
    <Layout backgroundImage={backgroundImage}>
      <SEO yoast={{ meta: yoast_meta }} pathname={path} image={image.localFile.childImageSharp.fixed.src} article />
      <div className="post-template">
        <div className="container py-5">
          <Link
            className="post-template-back font-size-xsm font-family-secondary font-weight-s color-text-secondary"
            to={`/blog/`}
          >
            <img className="d-inline-block mr-3" src={imgIconBack} alt="" />
            {language === 'nl_NL'
              ? `Terug naar het overzicht`
              : `Back to the overview`}
          </Link>
        </div>
        <div className="pb-5">
          {descriptionPartOne && (
            <div className="container post-container">
              <BoxDefault className="px-3 px-lg-5 py-4">
                <TitleDefault asH1>
                  <div dangerouslySetInnerHTML={{ __html: title }} />
                </TitleDefault>
                <ContentDefault className="mt-3">
                  <div dangerouslySetInnerHTML={{ __html: introText }} />
                </ContentDefault>
                <Img
                  className="mt-4"
                  fluid={image.localFile.childImageSharp.fluid}
                />
                <ContentDefault className="mt-5">
                  <div
                    dangerouslySetInnerHTML={{ __html: descriptionPartOne }}
                  />
                </ContentDefault>
              </BoxDefault>
            </div>
          )}
          {quote && (
            <div className="post-template-quote color-background-contrast mt-5 py-5">
              <div className="container post-container py-2 px-5 font-family-secondary font-weight-s font-size-xl color-text-light">
                <div className="px-5 position-relative">
                  <div className="post-template-quote-icon position-absolute">
                    &ldquo;
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: quote }} />
                </div>
              </div>
            </div>
          )}
          {descriptionPartTwo && (
            <div className="container post-container mt-5">
              <BoxDefault className="px-4 px-md-5 py-4">
                <ContentDefault className="mt-3">
                  <div
                    dangerouslySetInnerHTML={{ __html: descriptionPartTwo }}
                  />
                </ContentDefault>
              </BoxDefault>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Post
